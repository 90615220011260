import { Route, Routes, useSearchParams } from "react-router-dom";
import Header from "./pages/Header";
import Return from "./pages/Return";
import Cancel from "./pages/Cancel";

function App() {
  const url = useSearchParams();
  console.log(url, "rul");
  return (
    <Routes>
      <Route path="/" element={<Header />}></Route>
      <Route path="/return" element={<Return />}></Route>
      <Route path="/cancel" element={<Cancel />}></Route>
    </Routes>
  );
}

export default App;
