import React from "react";
import "../styles/style.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="image-container">
        <img src={require("../assests/images/footerimg.png")} alt="" />
      </div>
      <div className="icon-container">
        <img src={require("../assests/images/Footerlogo.png")} alt="" />
      </div>
      <div className="footertext justify-content-center ">
        <a href="#Features" className="text-decoration-none text-light">
          <p className="footerptag">Features</p>
        </a>
        <a href="#Review" className="text-decoration-none text-light">
          <p className="footerptag">Reviews</p>
        </a>
        <a href="#Download" className="text-decoration-none text-light">
          <p className="footerptag">Download App</p>
        </a>
        <a href="#contact" className="text-decoration-none text-light">
          <p className="footerptag">Contact Us</p>
        </a>
        <p className="footerptag">Terms & Condition</p>
        <p className="ptag">Privacy Policy</p>
      </div>
      <div className="social-icons text-center ">
        <img
          src={require("../assests/images/facebook.png")}
          className="ps-3"
          alt=""
        />
        <img
          src={require("../assests/images/twitter.png")}
          className="ps-3"
          alt=""
        />
        <img
          src={require("../assests/images/insta.png")}
          className="ps-3"
          alt=""
        />
      </div>
      <div className="footer-copyright">
        <p>© 2023 Hoopgng | All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
