import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import QuoteImg from "../assests/images/quoteimg.svg";
import QuoteImg2 from "../assests/images/quoteimg2.svg";
import DownloadPage from "./Download";

function ReviewPage() {
  const options = {
    items: 1,
    loop: false,
    margin: 20,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      767: {
        items: 2,
      },
      991: {
        items: 3,
      },
    },
  };
  return (
    <>
      <div className="reviewpage-container" id="Review">
        <div className="feature-page-content">
          <h1 className="reviewicon">
            Our users love <span className="">this app?</span>
          </h1>
          <img
            className="reviewiconimg "
            src={require("../assests/images/Abouticon.png")}
            alt="Features Icon"
          />
        </div>
        <div className="text-center">
          <p className="aboutheading">
            Hear what our customers are saying about us
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <OwlCarousel className="owl-theme" {...options}>
                <div class="item">
                  <div className="usercard mb-3">
                    <div className="card-header d-flex">
                      <div>
                        <h5>Andy, Shooting coach</h5>
                        <div className="d-flex align-items-center star ">
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="text-body-tertiary h3">&#9733;</span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <img src={QuoteImg} className="quoteimg" alt="" />
                        Hoopgng is an amazing app for basketball trainers and
                        shooting coaches!
                        <img src={QuoteImg2} className="quoteimg2" alt="" />
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="usercard mb-3">
                    <div className="card-header d-flex">
                      <div>
                        <h5>David, AAU coach</h5>
                        <div className="d-flex align-items-center star ">
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <img src={QuoteImg} className="quoteimg" alt="" />
                        Hoopgng is the go to app when we go on vacation and my
                        daughter wants to get shots up somewhere
                        <img src={QuoteImg2} className="quoteimg2" alt="" />
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="usercard mb-3">
                    <div className="card-header d-flex">
                      <div>
                        <h5>Shelley, mom of a gym rat</h5>
                        <div className="d-flex align-items-center star ">
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="starblue h3">&#9733;</span>
                          <span className="text-body-tertiary h3">&#9733;</span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <img src={QuoteImg} className="quoteimg" alt="" />
                        Hoopgng is one stop, simple spot for me to find
                        basketball courts for my players
                        <img src={QuoteImg2} className="quoteimg2" alt="" />
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <DownloadPage />
    </>
  );
}

export default ReviewPage;
