import React from "react";
import FeaturePage from "./Features";
import Aboutmobile from "../assests/images/Aboutimg.png";

function AboutPage() {
  return (
    <>
      <div className="aboutpage-container" id="About">
        <div className="feature-page-content">
          <h1 className="abouticon">
            About <span className="">Us</span>
          </h1>
          <img
            className="abouticonimg "
            src={require("../assests/images/Abouticon.png")}
            alt="Features Icon"
          />
        </div>
        <div className="text-center">
          <p className="aboutheading">
            Hear what our customers are saying about us
          </p>
        </div>
        <div className="container">
          <div className="d-flex row justify-content-center align-items-center">
            <div className="col-lg-5 d-flex justify-content-center align-items-center mb-5 mb-lg-0">
              <img src={Aboutmobile} alt="Mobile" className="img-fluid" />
            </div>
            <div className="col-lg-5">
              <div className="aboutparagph">
                <p>
                  &#11043;&nbsp;Family owned and operated with real-life
                  application, both as facility owners and users
                </p>
                <p>
                  &#11043;&nbsp;Books the Official Practice facility of the
                  Grand Rapids Gold (Denver Nuggets G-League Team)
                </p>
                <p>
                  &#11043;&nbsp;Used by everyone from elementary schoolers to
                  high-level professionals
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeaturePage />
    </>
  );
}

export default AboutPage;
