import React from "react";
import Homemobile from "../assests/images/homechange.png";
import "../styles/style.css";
import AboutPage from "./About";

const HomePage = () => {
  return (
    <>
      <div className="homepage-container" id="Home">
        <div className="homepage-content">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="text-container">
                  <h1>
                    <span className="fonttext"> Train </span>
                    <span className="fonttext1"> On Your</span> <br />
                    <span className="fonttext"> Own </span>
                    <span className="fonttext1"> Time</span>
                  </h1>
                  <h3>The new way business is done!</h3>
                  <h5 className="homepara">
                    Connecting you with top providers in your area 7 days a
                    week. Book, Shop, Send, Pay and More with Hoopgng.
                  </h5>
                  <a href="#Download">
                    <button className="btn Homebtn ps-3 pt-2 text-center">
                      <span className=""> Book a Facility </span>
                      <i className="bi bi-arrow-right ps-1"></i>
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center mt-5 mt-lg-0">
                <img src={Homemobile} alt="Mobile" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutPage />
    </>
  );
};

export default HomePage;
