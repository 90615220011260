import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function Return() {
  return (
    <div className="payment-container">
      <div className="payment-card">
        <div className="payment-header">
          <i className="check-mark-success">✓</i>
        </div>
        <h1 className="payment-success-header">Success</h1>
        <p className="payment-success-message">
          Your court or gym booking was successful. <br />
          <br />
          We're excited to have you with us. Get ready to enjoy your time!
        </p>
      </div>
    </div>
  );
}

export default Return;
