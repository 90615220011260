import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import logo from "../assests/images/newlogo.png";
import "../styles/style.css";
import HomePage from "./Home";
import Icon from "../assests/images/icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [showBackToTop, setShowBackToTop] = useState(true);
  const handleScroll = () => {
    if (window.scrollY > 70) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <div>
              <a className="navbar-brand" href="logo">
                <img src={logo} alt="Logo" />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse justify-content-end navbar-collapse "
              id="navbarNav"
            >
              <div className="">
                <ul className="navbar-nav ml-lg-auto ms-2">
                  {" "}
                  <li className="nav-item ms-lg-3 ">
                    <a className="nav-link headertext" href="#Home">
                      Home
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3 ">
                    <a className="nav-link headertext" href="#About">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Features">
                      Features
                    </a>
                  </li>
                  <li className="nav-item ms-lg-3">
                    <a className="nav-link headertext" href="#Review">
                      Reviews
                    </a>
                  </li>
                  <li className="nav-item mb-lg-0 mb-3">
                    <a href="#Download">
                      <button className="btn appbtn m-auto ms-lg-3 appbtntext">
                        <img
                          src={Icon}
                          alt="Download Icon"
                          className="appbtntext"
                        />
                        <span className="appbtntext"> Download App</span>
                      </button>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#contact" className="text-decoration-none">
                      <button className="nav-link btn ms-lg-3 contactbtn">
                        <i className="bi bi-telephone contactbtntext "></i>
                        <span className="contactbtntext"> Contact Us</span>
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <HomePage />
      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} color="white" size="lg" />
        </button>
      )}
    </>
  );
};

export default Header;
