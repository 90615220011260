import React, { useState } from "react";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

function ContactPage() {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const [emailValid, setEmailValid] = useState(true);

  const validateEmail = (email) => {
    const isValid = emailPattern.test(email);
    setEmailValid(isValid);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "email") {
      validateEmail(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailValid) {
      toast.error("Invalid email address");
      return;
    }

    try {
      const apiUrl = "https://api.hoopgng.com/api/v2/user/contact-us";
      const requestData = {
        email: formData.email,
        subject: formData.subject,
        message: formData.message,
      };

      const response = await Axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("API Response:", response.data);
      if (response.status === 200) {
        toast.success("Thank you! Your query has been sent to our admin team");
      }

      setFormData({
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Oops! Something went wrong. Please try again later");
    }
  };

  return (
    <>
      <div className="contactpage-container" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 contactpage">
              <div className="feature-page-content">
                <h1 className="contacticon">
                  Contact <span className="">Us</span>
                </h1>
                <img
                  className="contactusimg "
                  src={require("../assests/images/Abouticon.png")}
                  alt="Features Icon"
                />
              </div>
              <div className="d-flex icon">
                <div className="">
                  <img
                    src={require("../assests/images/Telephone.jpg")}
                    alt=""
                  />
                </div>
                <div className="pt-2 ps-3">
                  <p className="contacticontext">(616) 915-9031</p>
                </div>
              </div>
              <div className="d-flex pt-4">
                <div className="">
                  <img src={require("../assests/images/Mail.jpg")} alt="" />
                </div>
                <div className="pt-2 ps-3">
                  <p className="contacticontext">hoopgng1@gmail.com</p>
                </div>
              </div>
              <div className="d-flex pt-4">
                <div className="">
                  <img src={require("../assests/images/Location.jpg")} alt="" />
                </div>
                <div className="pt-2 ps-3">
                  <p className="contacticontext">4195 E Paris SE</p>
                </div>
              </div>
              <div className="d-flex pt-4">
                <div className="">
                  <img src={require("../assests/images/Clock.jpg")} alt="" />
                </div>
                <div className="pt-2 ps-3">
                  <p className="contacticontext">Opening Hours</p>
                </div>
              </div>
              <div className="context">
                <p>
                  <span className="conatactBluetext">09 AM to 05 PM</span>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form">
                <form onSubmit={handleSubmit}>
                  <div className="form-group pb-3">
                    <label>Email Address</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={`form-control ${
                        emailValid ? "" : "is-invalid"
                      } m-0`}
                      placeholder="Email"
                      required
                    />
                    {!emailValid && (
                      <div className="invalid-feedback ">
                        Please enter a valid email address.
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="pt-2">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Write you subject here"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      rows="4"
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Write you message here"
                      required
                    ></textarea>
                  </div>
                  <button className="btn submitbtn text-center">
                    <span className=""> Submit </span>
                    <i className="bi bi-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <Footer />
    </>
  );
}

export default ContactPage;
