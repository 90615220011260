import React from "react";

function Cancel() {
  return (
    <div className="payment-container">
      <div className="payment-card">
        <div className="payment-faield-header-container">
          <i className="check-mark-faield">✗</i>
        </div>
        <br />
        <h1 className="payment-faield-header">Failed</h1>
        <p className="payment-success-message">
          It looks like there was an issue processing your payment for your
          court or gym booking. Unfortunately, your booking was not completed.
          <br />
          <br />
          Please double-check your payment details and try again. If you
          continue to experience issues, feel free to contact our support team
          for assistance.
        </p>
      </div>
    </div>
  );
}

export default Cancel;
